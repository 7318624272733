import {message} from 'ant-design-vue'

import $store from '@/store'
import $router from '@/router'
import $api from '@/api'

const beforeEnter = async (to: any, from: any) => {
    if ($store.getters['school/logged']) {
        return true
    }

    await $api.get('/school/school_user/logged').then(({data: {status, msg, result}}: any) => {
        if (1 == status) {
            $store.commit('school/logged', result)
        } else {
            message.error(msg)
            $router.push('/')
        }
    })
    return true
}

export default [
    {
        path: '/school',
        component: () => import('@/page/school/SchoolLayoutMain.vue'),
        meta: {
            title: '首页',
        },
        children: [
            {path: '/school/:pathMatch(.*)', component: () => import('@/page/school/NotFond.vue')},
            {path: '/school', component: () => import('@/page/school/index/SchoolIndexIndex.vue')},
            {
                path: '/school/exam_machine/lists',
                component: () => import('@/page/school/exam_machine/SchoolExamMachineLists.vue')
            },
            {
                path: '/school/exam_plan/index',
                component: () => import('@/page/school/exam_plan/SchoolExamPlanIndex.vue')
            },
            {
                path: '/school/exam_room/lists',
                component: () => import('@/page/school/exam_room/SchoolExamRoomLists.vue')
            },
            {
                path: '/school/exam_practice_user/lists',
                component: () => import('@/page/school/exam_practice_user/SchoolExamPracticeUserLists.vue')
            },
            {
                path: '/school/exam_sign_up/lists',
                component: () => import('@/page/school/exam_sign_up/SchoolExamSignUpLists.vue')
            },
            {path: '/school/invoice/lists', component: () => import('@/page/school/invoice/SchoolInvoiceLists.vue')},
            {
                path: '/school/invoice/audit_lists',
                component: () => import('@/page/school/invoice/SchoolInvoiceAuditLists.vue')
            },
            {path: '/school/notice/lists', component: () => import('@/page/school/notice/SchoolNoticeLists.vue')},
            {
                path: '/school/school_course/lists',
                component: () => import('@/page/school/school_course/SchoolSchoolCourseLists.vue')
            },
            {
                path: '/school/school_course_attachment/lists',
                component: () => import('@/page/school/school_course_attachment/SchoolSchoolCourseAttachmentLists.vue')
            },
            {
                path: '/school/school_exam_sign_up/lists',
                component: () => import('@/page/school/school_exam_sign_up/SchoolSchoolExamSignUpLists.vue')
            },
            {
                path: '/school/school_group/lists',
                component: () => import('@/page/school/school_group/SchoolSchoolGroupLists.vue')
            },
            {path: '/school/school/edit', component: () => import('@/page/school/school/SchoolSchoolEdit.vue')},
            {
                path: '/school/school/edit/:activeKey',
                component: () => import('@/page/school/school/SchoolSchoolEdit.vue')
            },
            {
                path: '/school/school_train_plan/lists',
                component: () => import('@/page/school/school_train_plan/SchoolSchoolTrainPlanLists.vue')
            },
            {
                path: '/school/school_train_plan_student/lists',
                component: () => import('@/page/school/school_train_plan_student/SchoolSchoolTrainPlanStudentLists.vue')
            },
            {
                path: '/school/school_train_sign_up/lists',
                component: () => import('@/page/school/school_train_sign_up/SchoolSchoolTrainSignUpLists.vue')
            },
            {
                path: '/school/school_user/lists',
                component: () => import('@/page/school/school_user/SchoolSchoolUserLists.vue')
            },
            {
                path: '/school/school_user/edit',
                component: () => import('@/page/school/school_user/SchoolSchoolUserEdit.vue')
            },
            {
                path: '/school/school_user_enable_log/lists',
                component: () => import('@/page/school/school_user_enable_log/SchoolSchoolUserEnableLogLists.vue')
            },
            {path: '/school/student/lists', component: () => import('@/page/school/student/SchoolStudentLists.vue')},
            {
                path: '/school/student_certificate/lists',
                component: () => import('@/page/school/student_certificate/SchoolStudentCertificateLists.vue')
            },
            {
                path: '/school/student_course_progress/lists',
                component: () => import('@/page/school/student_course_progress/SchoolStudentCourseProgressLists.vue')
            },
            {
                path: '/school/student_course_progress_log/lists',
                component: () => import('@/page/school/student_course_progress_log/SchoolStudentCourseProgressLogLists.vue')
            },
            {
                path: '/school/student_exam/lists',
                component: () => import('@/page/school/student_exam/SchoolStudentExamLists.vue')
            },
            {
                path: '/school/train_sign_up/lists',
                component: () => import('@/page/school/train_sign_up/SchoolTrainSignUpLists.vue')
            },
            {
                path: '/school/train_sign_up/audit',
                component: () => import('@/page/school/train_sign_up/SchoolTrainSignUpAudit.vue')
            },
            {
                path: '/school/camera',
                component: () => import('@/page/school/exam_monitor/ExamCamera.vue')
            },
            {
                path: '/school/go',
                component: () => import('@/page/school/sys/ExamMonitor.vue')
            },
            {
                path: '/school/fild',
                component: () => import('@/page/school/sys/SchoolSysFild.vue')
            },
            {
                path: '/school/approval',
                component: () => import('@/page/school/sys/SchoolSysApproval.vue')
            },
            {
                path: '/school/mail',
                component: () => import('@/page/school/sys/SchoolSysMail.vue')
            },
        ],
        beforeEnter,
    },
]
