import $api from '@/api'

export default class EnumHelper {
    static enum: any

    // 通过枚举值获取名称，可设置默认值
    static getNameByValueOrDefault(key: string, value: number, defaultValue: string) {
        const enumValue = this.getNameByValue(key, value)
        return enumValue ? enumValue : defaultValue
    }

    // 通过枚举值获取名称
    static getNameByValue(key: string, value: number) {
        const options = this.get(key)
        for (const optionsKey in options) {
            const option = options[optionsKey]
            if (option.value === value) {
                return option.name
            }
        }
        return ''
    }

    // 通过枚举值获取名称和颜色
    static getNameColorByValue(key: string, value: number) {
        const options = this.get(key)
        for (const optionsKey in options) {
            const option = options[optionsKey]
            if (option.value === value) {
                if (option.styleClass) {
                    return `<span style={{color: option.styleClass}}>{option.name}</span>`
                } else {
                    return option.name
                }
            }
        }
        return ''
    }

    // 通过枚举值获取
    static findByKeyAndValue(key: string, value: number) {
        const options = this.get(key)
        for (const optionsKey in options) {
            const option = options[optionsKey]
            if (option.value === value) {
                return option
            }
        }
        return ''
    }

    // 通过常量名 获取 枚举值
    static getValueByConstant(key: string, constant: string) {
        const options = this.get(key)
        for (const optionsKey in options) {
            const option = options[optionsKey]
            if (option.constant === constant) {
                return option.value
            }
        }
        return 0
    }

    static getFilters(key: string): any[] {
        const enums = this.get(key)
        const filters: any[] = []
        enums.forEach((v: any, k: any) => {
            filters.push({label: v.name, value: v.value})
        })
        return filters
    }


    // 获取枚举
    static get(key: string) {
        const enums = this.enum
        return enums[key] ? JSON.parse(JSON.stringify(enums[key])) : []
    }


    // 初始化枚举缓存
    static init(callback: any) {
        $api.get('/plugin/enum/get_all')
            .then(({data: {result}}: any) => {
                this.enum = result
                callback()
            })
    }
}
